import React, { useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@andes/button';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';
import Modal from '@andes/modal';
import Snackbar from '@andes/snackbar';
import { forEach } from '../../lib/dom-utils';
import { validateSnackbarColor } from '../../utils/validators';
import ModalContent from './modal-content';
import { trackEvent } from '../../lib/tracking';
import Questions from '../../pages/vip/features/questions/questions-modal';
import QuestionsUnregistered from '../../pages/vip/features/questions/questions-unregistered-modal';
import UnregisteredActions from '../../pages/vip/features/unregistered-actions';

const DEFAULT_DELAY_TIME = 3000;

const namespace = 'ui-vip-modal-request';

const ActionRequestModal = ({
  buttonProps,
  className,
  modalTitle,
  modalClassName,
  closeModalLabel,
  itemId,
  loginType,
  featureName,
  redirectToLogin,
  isLoginRequired,
  fetchModalRequest,
  deviceType,
  track,
  isOpenModal,
  isFetching,
  message_modal,
  label,
  removeRecaptcha,
  modalResizing,
  ajax_backend_params,
  actionType,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [componentIsMounted, setComponentIsMounted] = useState(false);

  useEffect(() => {
    if (showModal) {
      setShowLoading(isFetching);
      setComponentIsMounted(!isFetching);
    }
    if (showModal && !showLoading) {
      setShowModal(isOpenModal);
    }
  }, [showModal, showLoading, isFetching, isOpenModal]);

  const openModal = e => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      if (deviceType !== 'mobile') {
        // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
        // dejado por la scrollbar en los casos que se utilice mouse
        // checks para omitir el test del dom
        const main = document.querySelectorAll('body > header, body > main') || [];

        forEach(main, element => {
          element.classList.add('with-scroll-bars-hidden');
        });
      }
      trackEvent(track);
      setShowLoading(true);
      if (isLoginRequired && redirectToLogin) {
        redirectToLogin(itemId, loginType, featureName);
        setShowModal(false);
      } else {
        fetchModalRequest(itemId, ajax_backend_params ? ajax_backend_params.variation_id : null, actionType);
        setShowModal(true);
      }
    }
  };

  const hideModal = () => {
    if (deviceType !== 'mobile') {
      // Se calculan en base al react-remove-scroll-bar y cumplen la función de suplantar el espacio
      // dejado por la scrollbar en los casos que se utilice mouse
      const main = document.querySelectorAll('body > header, body > main') || [];

      forEach(main, element => {
        element.classList.remove('with-scroll-bars-hidden');
      });
    }
    removeRecaptcha();
    setShowModal(false);
    setShowLoading(false);
    setComponentIsMounted(false);
  };
  return (
    <div className={className}>
      <Button
        hierarchy="quiet"
        loading={showLoading}
        className={`${namespace}-button`}
        data-testid="button-modal"
        onClick={openModal}
        {...buttonProps}
      >
        {label && label.text}
      </Button>
      <Modal
        title={modalTitle}
        className={classNames(`${namespace}-modal`, modalClassName)}
        open={showModal}
        onClose={hideModal}
        type={deviceType === 'mobile' ? 'full' : 'large'}
        maxWidth={682}
        closeButtonSrLabel={closeModalLabel}
      >
        <ModalContent
          modalClassName={modalClassName}
          componentIsMounted={componentIsMounted}
          autoHeight={false}
          resizing={modalResizing}
        >
          {isFetching ? (
            <ProgressIndicatorCircular modifier="fullscreen" size="large" />
          ) : (
            <>
              <Questions />
              <QuestionsUnregistered />
              <UnregisteredActions />
            </>
          )}
        </ModalContent>
      </Modal>
      {message_modal && message_modal.text && (
        <Snackbar
          delay={DEFAULT_DELAY_TIME}
          message={message_modal.text}
          color={validateSnackbarColor(message_modal.type)}
          show
        />
      )}
    </div>
  );
};

ActionRequestModal.propTypes = {
  buttonProps: shape().isRequired,
  className: string,
  modalTitle: string,
  modalClassName: string,
  itemId: string.isRequired,
  fetchModalRequest: func.isRequired,
  deviceType: string,
  track: shape({}).isRequired,
  isFetching: bool.isRequired,
  isOpenModal: bool,
  message_modal: shape({}),
  label: shape({
    text: string,
  }),
  modalResizing: bool,
  removeRecaptcha: func.isRequired,
  loginType: string,
  featureName: string,
  redirectToLogin: func,
  isLoginRequired: bool,
  closeModalLabel: string.isRequired,
  ajax_backend_params: shape({}),
  actionType: string,
};

ActionRequestModal.defaultProps = {
  ajax_backend_params: null,
  className: null,
  deviceType: null,
  modalTitle: null,
  modalClassName: null,
  message_modal: null,
  label: null,
  isOpenModal: false,
  modalResizing: null,
  loginType: null,
  featureName: null,
  redirectToLogin: null,
  isLoginRequired: false,
};

export default ActionRequestModal;
