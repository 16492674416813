import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actionsVis from '../../../../actions/vip/vis';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import Questions from '../../../../components/questions/questions.desktop';

const FEATURE_NAME = 'questions_modal';

const mapStateToProps = ({
  [FEATURE_NAME]: {
    id,
    form,
    question_message,
    track,
    show_track,
    title,
    messages,
    disabled,
    url,
    stockModal,
    condition,
    ...rest
  },
  baseUrl,
  id: itemId,
}) => {
  const incomingQuestionsModel = rest.questions;

  return {
    id,
    form,
    question_message,
    title,
    track,
    show_track,
    messages,
    disabled,
    url: `${baseUrl}/${itemId}/questions`,
    stockModal,
    condition,
    ...rest,
    ...incomingQuestionsModel,
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateQuestion: ({ itemId, text, track, checkbox }) =>
    dispatch(actionsVis.onCreateOnlyQuestion({ itemId, text, track, checkbox })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Questions);
export { mapDispatchToProps };
