import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip/vis';
import ActionRequestModal from '../../../../components/action-request-modal';

const FEATURE_NAME = 'questions_modal';

const mapStateToProps = (
  state,
  {
    className,
    itemId,
    buttonProps,
    deviceType,
    label,
    modalTitle,
    closeModalLabel,
    modalClassName,
    track,
    isLoginRequired,
    ajax_backend_params,
    actionType,
  },
) => ({
  id: state.id,
  className,
  itemId,
  buttonProps,
  deviceType,
  label,
  modalTitle,
  closeModalLabel,
  modalClassName,
  track,
  isFetching: !state.questions_modal,
  isOpenModal: state.modal_request && state.modal_request.isOpen,
  message_modal: state.message_modal,
  featureName: FEATURE_NAME,
  loginType: 'question',
  isLoginRequired,
  ajax_backend_params,
  actionType,
});

const mapDispatchToProps = dispatch => ({
  fetchModalRequest: (itemId, variationId, actionType) =>
    dispatch(actions.fetchModalQuestions(itemId, variationId, actionType)),
  removeRecaptcha: () => dispatch(actions.removeRecaptcha(FEATURE_NAME)),
  redirectToLogin: (itemId, loginType, featureName) =>
    dispatch(actions.redirectToLogin({ itemId, loginType, featureName })),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(ActionRequestModal);
export { mapDispatchToProps };
