import React from 'react';
import { shape, string } from 'prop-types';
import Media from '../media';

const namespace = 'ui-pdp-unregistered-actions';

const UnregisteredActions = ({ login_action, registration_action }) => (
  <div className={`${namespace}__container`}>
    <Media className={`${namespace}__login`} action={login_action} />
    <Media className={`${namespace}__registration`} action={registration_action} />
  </div>
);

UnregisteredActions.propTypes = {
  login_action: shape({
    label: shape({
      text: string.isRequired,
    }).isRequired,
    target: string.isRequired,
  }).isRequired,
  registration_action: shape({
    label: shape({
      text: string.isRequired,
    }).isRequired,
    target: string.isRequired,
  }).isRequired,
};

export default UnregisteredActions;
