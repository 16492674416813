import React, { useRef, useEffect } from 'react';
import { node, string, number, bool } from 'prop-types';
import classnames from 'classnames';

const namespace = 'ui-pdp-modal-content-autoheight';
const headerSelector = '.andes-modal__header';

const ModalContentAutoheight = ({
  children,
  className,
  modalClassName,
  extra,
  viewportPadding,
  componentIsMounted,
  autoHeight,
}) => {
  const ref = useRef();

  useEffect(() => {
    /* istanbul ignore next */
    if (componentIsMounted && autoHeight) {
      const contentHeight = ref.current.clientHeight;
      const container = document.querySelector('.andes-modal__content');
      let headerDiff = 0;
      if (container) {
        const viewportHeight = window.innerHeight;
        const header = headerSelector;
        headerDiff = header ? header.clientHeight : 0;
        const availableHeight = viewportHeight - viewportPadding;
        const newModalHeight = contentHeight + headerDiff + extra;
        if (newModalHeight <= availableHeight) {
          container.style.maxHeight = `${newModalHeight}px`;
        } else {
          container.style.maxHeight = `${availableHeight}px`;
        }
      }
    }
  }, [autoHeight, componentIsMounted, extra, modalClassName, ref, viewportPadding]);

  return (
    <div className={classnames(namespace, className, `${namespace}__container`)} ref={ref}>
      {children}
    </div>
  );
};

ModalContentAutoheight.propTypes = {
  children: node,
  className: string,
  extra: number,
  modalClassName: string.isRequired,
  viewportPadding: number,
  componentIsMounted: bool,
  autoHeight: bool,
};
ModalContentAutoheight.defaultProps = {
  children: null,
  className: null,
  extra: 0,
  viewportPadding: 96,
  componentIsMounted: false,
  autoHeight: true,
};

export default ModalContentAutoheight;
