import React from 'react';
import { bool, string, shape } from 'prop-types';
import ActionRequestModalQuestion from '../../../../pages/vip/features/action-request-modal/action-request-modal.questions';

const namespace = 'ui-pdp-action';

const BuildRequestModalQuestions = ({
  modal_title,
  close_modal_label,
  itemId,
  type,
  hierarchy,
  label,
  deviceType,
  track,
  is_login_required,
  ajax_backend_params,
  actionType,
}) => {
  const buttonProps = {
    type,
    hierarchy,
  };

  return (
    <ActionRequestModalQuestion
      className={`${namespace}-container-request-modal`}
      itemId={itemId}
      buttonProps={buttonProps}
      deviceType={deviceType}
      label={label}
      modalTitle={modal_title}
      closeModalLabel={close_modal_label}
      modalClassName={`${namespace}-modal-questions`}
      track={track}
      isLoginRequired={is_login_required}
      ajax_backend_params={ajax_backend_params}
      actionType={actionType}
    />
  );
};

BuildRequestModalQuestions.propTypes = {
  modal_title: string,
  close_modal_label: string.isRequired,
  track: shape({}),
  type: string.isRequired,
  deviceType: string,
  itemId: string.isRequired,
  hierarchy: string,
  label: shape({
    text: string,
  }),
  is_login_required: bool,
  ajax_backend_params: shape({}),
  actionType: string.isRequired,
};

BuildRequestModalQuestions.defaultProps = {
  modal_title: null,
  track: null,
  deviceType: null,
  hierarchy: null,
  label: null,
  is_login_required: false,
};

export default BuildRequestModalQuestions;
