import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import UnregisteredActions from '../../../../components/unregistered-actions';

const FEATURE_NAME = 'unregistered_actions';

const mapStateToProps = ({ [FEATURE_NAME]: { id, login_action, registration_action, ...rest } }) => ({
  id,
  login_action,
  registration_action,
  ...rest,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(UnregisteredActions);
